import request from '../utils/request';

let baseUrl;
let adminApi;
if(process.env.NODE_ENV === 'production'){
    baseUrl =  process.env.VUE_APP_BASE_URL
    adminApi = process.env.VUE_APP_ADMIN_API
}else{
    baseUrl = '/baseUrl'
    adminApi = '/adminApi'
}



export const Login = p => {
    return request({
        url: baseUrl + '/system/auth/loginSingle',
        method: 'post',
        data: p
    })
}

/**
 * 布草分类统计
 */
export const getBigSkuCategory = (p) => {
    return request({
        url: baseUrl + '/rent/big/getBigSkuCategory',
        method: 'get',
        params: p
    });
}

/**
 * 布草收发年，月，日统计
 */
export const getBigSkuCount = (p) => {
    return request({
        url: baseUrl + '/rent/big/getBigSkuCount',
        method: 'get',
        params: p
    });
}

/**
 * 布草报废排名
 */
export const getBigSkuDispose = (p) => {
    return request({
        url: baseUrl + '/rent/big/getBigSkuDispose',
        method: 'get',
        params: p
    });
}

/**
 * 布草洗涤次数排名
 */
export const getBigSkuWashSpu = (p) => {
    return request({
        url: baseUrl + '/rent/big/getBigSkuWashSpu',
        method: 'get',
        params: p
    });
}

/**
 * 实时监控
 */
export const getBigSkuDivision = (p) => {
    return request({
        url: baseUrl + '/rent/big/getBigSkuDivision',
        method: 'get',
        params: p
    });
}

/**
 * 
 * // 库存预警
 */

export const getSpuStockWarning = (p) => {
    return request({
        url: baseUrl + `/rent/big/getSpuStockWarning`,
        method: 'get',
        params: p
    })
}

/**
 * 
 * 今日报废，登记，出库，芯片更换数量
 */

export const getSkuCountToday = (p) => {
    return request({
        url: baseUrl + `/rent/big/getSkuCountToday`,
        method: 'get',
        params: p
    })
}

/**
 * 
 * 统计一年洗涤量趋势
 */

export const getBigSkuWashMonth = (p) => {
    return request({
        url: baseUrl + `/rent/big/getBigSkuWashMonth`,
        method: 'get',
        params: p
    })
}

/**
 * 
 * 织物洗涤次数分析
 */

export const getBigSkuWashCategoryTime = (p) => {
    return request({
        url: baseUrl + `/rent/big/getBigSkuWashCategoryTime`,
        method: 'get',
        params: p
    })
}

/**
 * 
 * 织物洗涤次数分析(新接口)
 */

export const getBigSkuWashCategory = (p) => {
    return request({
        url: baseUrl + `/rent/big/getBigSkuWashCategory`,
        method: 'get',
        params: p
    })
}

/**
 * 
 * 织物洗涤次数分析分类管理
 */

export const getCategoryPage = (p) => {
    return request({
        url: adminApi + `/rent/category/page`,
        method: 'get',
        params: p
    })
}